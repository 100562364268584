import moment from 'moment';

import type { TrelloWindow } from '@trello/window-types';

declare const window: TrelloWindow;

import { makeYearAwareCalendar } from './makeYearAwareCalendar';

moment.locale(window.locale, {
  months: [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ],
  monthsShort: [
    'มกรา',
    'กุมภา',
    'มีนา',
    'เมษา',
    'พฤษภา',
    'มิถุนา',
    'กรกฎา',
    'สิงหา',
    'กันยา',
    'ตุลา',
    'พฤศจิกา',
    'ธันวา',
  ],
  weekdays: [
    'อาทิตย์',
    'จันทร์',
    'อังคาร',
    'พุธ',
    'พฤหัสบดี',
    'ศุกร์',
    'เสาร์',
  ],
  weekdaysShort: [
    'อาทิตย์',
    'จันทร์',
    'อังคาร',
    'พุธ',
    'พฤหัส',
    'ศุกร์',
    'เสาร์',
  ], // yes, three characters difference
  weekdaysMin: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],
  longDateFormat: {
    LT: 'H นาฬิกา m นาที',
    LTS: 'H นาฬิกา m นาที s วินาที',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY เวลา H นาฬิกา m นาที',
    LLLL: 'D MMMM',
  },
  // see `app/src/components/DateRangePicker/getLocalizedPlaceholder.ts`
  placeholder: {
    l: 'ปปปป/ด/ว',
    LT: null, // 'นาฬิกา นาที'? TODO https://trello.atlassian.net/browse/PANO-2026
  },
  meridiemParse: /ก่อนเที่ยง|หลังเที่ยง/,
  isPM(input) {
    return input === 'หลังเที่ยง';
  },
  meridiem(hour, minute, isLower) {
    if (hour < 12) {
      return 'ก่อนเที่ยง';
    } else {
      return 'หลังเที่ยง';
    }
  },
  calendar: makeYearAwareCalendar({
    sameDay: '[วันนี้ เวลา] LT',
    nextDay: '[พรุ่งนี้ เวลา] LT',
    nextWeek: 'dddd[หน้า เวลา] LT',
    lastDay: '[เมื่อวานนี้ เวลา] LT',
    lastWeek: '[วัน]dddd[ที่แล้ว เวลา] LT',
    sameYear: 'llll[หน้า เวลา] LT',
    sameElse: 'll[หน้า เวลา] LT',
  }),
  relativeTime: {
    future: 'อีก %s',
    past: '%sที่แล้ว',
    s: 'ไม่กี่วินาที',
    m: '1 นาที',
    mm: '%d นาที',
    h: '1 ชั่วโมง',
    hh: '%d ชั่วโมง',
    d: '1 วัน',
    dd: '%d วัน',
    M: '1 เดือน',
    MM: '%d เดือน',
    y: '1 ปี',
    yy: '%d ปี',
  },
});
